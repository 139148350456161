import * as React from "react";
import '../style/assets/sass/main.scss';
import { Helmet } from 'react-helmet';
import img1 from '../images/pic01.jpg';
import img2 from '../images/pic02.jpg';
import img3 from '../images/pic03.jpg';
import img4 from '../images/pic04.jpg';
import img5 from '../images/pic05.jpg';
import img6 from '../images/pic06.jpg';
import img7 from '../images/pic07.jpg';
import img8 from '../images/pic08.jpg';
import img9 from '../images/pic09.jpg';
import img10 from '../images/pic10.jpg';
import img11 from '../images/pic11.jpg';
// markup
const IndexPage = () => {
  return (
    <>
      <Helmet title="Alina Haute Coiffure" defer={false}>
        <script defer src='/js/html5up.js'></script>
      </Helmet>
      {/* <!-- Wrapper --> */}
      <div id="wrapper">

        {/* <!-- Main --> */}
        <div id="main">
          <div class="inner">

            {/* <!-- Header --> */}
            <header id="header">
              <a href="index.html" class="logo"><strong>Editorial</strong> by HTML5 UP</a>
              <ul class="icons">
                <li>
                  <a href="#" class="icon brands fa-twitter">
                    <span class="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Alina-Haute-Coiffure-260964847418841/"
                    class="icon brands fa-facebook-f">
                    <span class="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="icon brands fa-snapchat-ghost">
                    <span class="label">Snapchat</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/alinahautecoiffure/?hl=en" class="icon brands fa-instagram">
                    <span class="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a href="#" class="icon brands fa-medium-m">
                    <span class="label">Medium</span>
                  </a>
                </li>
              </ul>
            </header>

            {/* <!-- Banner --> */}
            <section id="banner">
              <div class="content">
                <header>
                  <h1>Alina Haute Coiffure</h1>
                  <p>A free and fully responsive site template</p>
                </header>
                <p>Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin aliquam facilisis ante
                  interdum congue. Integer mollis, nisl amet convallis, porttitor magna ullamcorper, amet
                  egestas mauris. Ut magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas.
                  Pellentesque sapien ac quam. Lorem ipsum dolor sit nullam.</p>
                <ul class="actions">
                  <li><a href="#" class="button big">Learn More</a></li>
                </ul>
              </div>
              <span class="image object">
                {/* <img src="images/pic10.jpg" alt="" /> */}
                <img src={img10} alt="" />
              </span>
            </section>

            {/* <!-- Section --> */}
            <section>
              <header class="major">
                <h2>Erat lacinia</h2>
              </header>
              <div class="features">
                <article>
                  <span class="icon fa-gem"></span>
                  <div class="content">
                    <h3>Portitor ullamcorper</h3>
                    <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                      facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  </div>
                </article>
                <article>
                  <span class="icon solid fa-paper-plane"></span>
                  <div class="content">
                    <h3>Sapien veroeros</h3>
                    <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                      facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  </div>
                </article>
                <article>
                  <span class="icon solid fa-rocket"></span>
                  <div class="content">
                    <h3>Quam lorem ipsum</h3>
                    <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                      facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  </div>
                </article>
                <article>
                  <span class="icon solid fa-signal"></span>
                  <div class="content">
                    <h3>Sed magna finibus</h3>
                    <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                      facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  </div>
                </article>
              </div>
            </section>

            {/* <!-- Section --> */}
            <section>
              <header class="major">
                <h2>Ipsum sed dolor</h2>
              </header>
              <div class="posts">
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic01.jpg" alt="" /> */}
                    <img src={img1} alt="" />
                  </a>
                  <h3>Interdum aenean</h3>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                    facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  <ul class="actions">
                    <li><a href="#" class="button">More</a></li>
                  </ul>
                </article>
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic02.jpg" alt="" /> */}
                    <img src={img2} alt="" />
                  </a>
                  <h3>Nulla amet dolore</h3>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                    facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  <ul class="actions">
                    <li><a href="#" class="button">More</a></li>
                  </ul>
                </article>
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic03.jpg" alt="" /> */}
                    <img src={img3} alt="" />
                  </a>
                  <h3>Tempus ullamcorper</h3>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                    facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  <ul class="actions">
                    <li><a href="#" class="button">More</a></li>
                  </ul>
                </article>
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic04.jpg" alt="" /> */}
                    <img src={img4} alt="" />
                  </a>
                  <h3>Sed etiam facilis</h3>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                    facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  <ul class="actions">
                    <li><a href="#" class="button">More</a></li>
                  </ul>
                </article>
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic05.jpg" alt="" /> */}
                    <img src={img5} alt="" />
                  </a>
                  <h3>Feugiat lorem aenean</h3>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                    facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  <ul class="actions">
                    <li><a href="#" class="button">More</a></li>
                  </ul>
                </article>
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic06.jpg" alt="" /> */}
                    <img src={img6} alt="" />
                  </a>
                  <h3>Amet varius aliquam</h3>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin aliquam
                    facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
                  <ul class="actions">
                    <li><a href="#" class="button">More</a></li>
                  </ul>
                </article>
              </div>
            </section>

          </div>
        </div>

        {/* <!-- Sidebar --> */}
        <div id="sidebar">
          <div class="inner">

            {/* <!-- Search --> */}
            <section id="search" class="alt">
              <form method="post" action="#">
                <input type="text" name="query" id="query" placeholder="Search" />
              </form>
            </section>

            {/* <!-- Menu --> */}
            <nav id="menu">
              <header class="major">
                <h2>Menu</h2>
              </header>
              <ul>
                <li><a href="/">Page d'accueil</a></li>
                <li><a href="generic.html">Generic</a></li>
                <li><a href="elements.html">Elements</a></li>
                <li>
                  <span class="opener">Submenu</span>
                  <ul>
                    <li><a href="#">Lorem Dolor</a></li>
                    <li><a href="#">Ipsum Adipiscing</a></li>
                    <li><a href="#">Tempus Magna</a></li>
                    <li><a href="#">Feugiat Veroeros</a></li>
                  </ul>
                </li>
                <li><a href="#">Etiam Dolore</a></li>
                <li><a href="#">Adipiscing</a></li>
                <li>
                  <span class="opener">Another Submenu</span>
                  <ul>
                    <li><a href="#">Lorem Dolor</a></li>
                    <li><a href="#">Ipsum Adipiscing</a></li>
                    <li><a href="#">Tempus Magna</a></li>
                    <li><a href="#">Feugiat Veroeros</a></li>
                  </ul>
                </li>
                <li><a href="#">Maximus Erat</a></li>
                <li><a href="#">Sapien Mauris</a></li>
                <li><a href="#">Amet Lacinia</a></li>
              </ul>
            </nav>

            {/* <!-- Section --> */}
            <section>
              <header class="major">
                <h2>Ante interdum</h2>
              </header>
              <div class="mini-posts">
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic07.jpg" alt="" /> */}
                    <img src={img7} alt="" />
                  </a>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
                </article>
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic08.jpg" alt="" /> */}
                    <img src={img8} alt="" />
                  </a>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
                </article>
                <article>
                  <a href="#" class="image">
                    {/* <img src="images/pic09.jpg" alt="" /> */}
                    <img src={img9} alt="" />
                  </a>
                  <p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
                </article>
              </div>
              <ul class="actions">
                <li><a href="#" class="button">More</a></li>
              </ul>
            </section>

            {/* <!-- Section --> */}
            <section>
              <header class="major">
                <h2>Get in touch</h2>
              </header>
              <p>Sed varius enim lorem ullamcorper dolore aliquam aenean ornare velit lacus, ac varius enim lorem
                ullamcorper dolore. Proin sed aliquam facilisis ante interdum. Sed nulla amet lorem feugiat
                tempus aliquam.</p>
              <ul class="contact">
                <li class="icon solid fa-envelope"><a href="#">alinahautecoiffure@gmail.com</a></li>
                <li class="icon solid fa-phone">(514) 303-0350</li>
                <li class="icon solid fa-home">3369 Rue Masson<br />
                  H1X 1R5, Montréal, Quebéc<br />
                  
                </li>
              </ul>
            </section>

            {/* <!-- Footer --> */}
            <footer id="footer">
              <p class="copyright">&copy; Untitled. All rights reserved. Demo Images: <a
                href="https://unsplash.com">Unsplash</a>. Design: <a href="https://html5up.net">HTML5
                  UP</a>.</p>
            </footer>

          </div>
        </div>

      </div>
    </>
  );
};

export default IndexPage;
